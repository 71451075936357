import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';

@Injectable({
  providedIn: 'root'
})
export class ManageClientService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }

  getClientEntities() {
    return this.http.get<any>(this.baseURL + 'ManageClientEntityUser/GetClientEntities');
  }

  addClientEntityUser(clientEntityUser,SelectedFeature) {
    const formData: FormData = new FormData();
    formData.append('clientEntityUser', JSON.stringify(clientEntityUser));
    formData.append('SelectedFeature',JSON.stringify(SelectedFeature));
    return this.http.post<Request>(this.baseURL + 'ManageClientEntityUser/AddClientEntityUser', formData);
  }

  updateClientEntityUser(clientEntityUser) {
    return this.http.post<Request>(this.baseURL + 'ManageClientEntityUser/UpdateClientEntityUser', clientEntityUser);
  }
  getcliententityUserById(clientEntityUserId) {
    return this.http.get<any>(this.baseURL + 'ManageClientEntityUser/GetClientEntityUser?ClientEntityuserId='+ clientEntityUserId);

  }
   
  deleteClientEntityUser(clientEntityUserId) {
    return this.http.get<Request>(this.baseURL + 'ManageClientEntityUser/DeleteClientEntityUser?clientEntityUserId=' + clientEntityUserId);
  }

  getClientEntityUserFeatures(clientEntityUserId) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(this.baseURL + 'ManageClientEntityUser/GetAssignedClientEntityUserFeatures?clientEntityUserId=' + clientEntityUserId);
  }
  deleteAssignedFeature(featureId, clientEntityUserId) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<Request>(this.baseURL + 'ManageClientEntityUser/DeleteAssignedFeature?clientEntityUserId=' + clientEntityUserId + '&&featureId=' + featureId);
  }
  getNotAssignedUserFeatures(clientEntityUserId) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(this.baseURL + 'ManageClientEntityUser/GetNotAssignedUserFeatures?clientEntityUserId=' + clientEntityUserId);
  }

  assignFeatures(features) {
    return this.http.post<Request>(this.baseURL + 'ManageClientEntityUser/AssignUserFeatures', features);
  }

  resetClientEntityUserPassword(clientEntityUserId) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(this.baseURL + 'ManageClientEntityUser/ResetClientEntityUserPassword?clientEntityUserId=' + clientEntityUserId );
  }
}
