import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';

@Injectable({
  providedIn: 'root'
})
export class ManageTicketService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }

  getFilterDropDowns() {
    return this.http.get<any>(this.baseURL + 'ManageTicket/GetFilterDropDowns');
  }
  getTickets() {
    return this.http.get<any>(this.baseURL + 'ManageTicket/GetTickets');
  }
  getTicketById(ticketId) {
    return this.http.get<Request>(this.baseURL + 'ManageTicket/GetTicketById?ticketId=' + ticketId);
  }
  addTicketReply(currentTicketId,newReplyMessage,adminEmail) {
    return this.http.get<Request>(this.baseURL + 'ManageTicket/AddTicketReply?ticketId=' + currentTicketId+"&replyMessage="+newReplyMessage+"&adminEmail="+adminEmail);
  }
  changeTicketStatus(currentTicketId,ticketStatus) {
    return this.http.get<Request>(this.baseURL + 'ManageTicket/ChangeTicketStatus?ticketId=' + currentTicketId+"&ticketStatusNumber="+ ticketStatus);
  }

 
}
